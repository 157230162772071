import 'regenerator-runtime/runtime.js'
import $ from 'jquery'

window.jQuery = window.$ = $

require('bootstrap')
//require('@fancyapps/fancybox')
require('imagesloaded')
require('select2')

import smoothscroll from 'smoothscroll-polyfill'

import './setupJQuery'
import './ajaxCollapseAfter'
import './slider'
import './longText'
import './search'
import './ajaxForm'
import { refreshCaptchaButton } from './ajaxForm'
import './openSection'
import ajaxLoad from './loadRelated'
import userAgreement from './userAgreement'
import welcomeText from './welcomeText'
//import './frontpageRelatedItems'
import './searchableFilter'
import initFancybox from './fancyboxUtils5'
import './nestedCheckboxes'
import './clearableInput'
import setupAnalyticsTags from './analytics'
import './vod.js'
import './carousels'
import './carousels2'
import './ajaxPagination'
import './ajaxGet'
import './profile'
//import './stripe.js'
import './alpineHelpers'
import initSticky from './initSticky'
import initIndex from './initIndex'
import blockModal from './blockModal'
import './initHtmx'
import hashAccordion from './hashAccordion'
import 'altcha'

// global

function loadBubbles() {
  return import(/* webpackChunkName: "bubbles" */ './bubbles')
    .then(() => {})
    .catch(err => console.log('error loading bubbles', err))
}


$.fn.select2.defaults.set('theme', 'bootstrap')

$(function() {
  smoothscroll.polyfill()
  userAgreement()
  welcomeText()
  setupAnalyticsTags()
  blockModal()

  window.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

  if (window.screen.width > 767 && $('#frontpage__bubbles').length) {
    loadBubbles()
  }

  // note: fix for ipad pdf in iframe
  // https://trello.com/c/GYYHQHJy/1311-pdf-download-advanced-search-zelo-dolgo-traja
  window.iOS =
    (/iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
    !window.MSStream

  initFancybox()

  $('[data-toggle="open-section"]').openSection()

  $('[data-toggle="ajax-load-content"]').each((index, el) => {
    ajaxLoad(el).then(() => {
      refreshCaptchaButton(el)
    })
  })

  $(
    '.filter-field--searchable > div, .form-checkboxes-multiple--searchable'
  ).searchableFilter()
  $('.nested-checkboxes').nestedCheckboxes()

  $('[data-add-to-wishlist]').activateWishlist()
  $('[data-add-to-playlist]').activatePlaylist()
  $('body').addClass('body--initialized')
  $('body').removeClass('preload')

  //NOTE: workaround for https://github.com/select2/select2/issues/5993
  $(document).on('select2:open', () => {
    document
      .querySelector('.select2-container--open .select2-search__field')
      .focus()
  })

  $(document).on('submit', 'form:not([data-custom-submit])', function() {
    $('button[type=submit], input[type=submit]', this).attr(
      'disabled',
      'disabled'
    )
  })

  function addUrlParameter(name, value) {
    var searchParams = new URLSearchParams(window.location.search)
    searchParams.set(name, value)
    window.location.search = searchParams.toString()
  }

  function constructURLForFormParams(url, form, name, value) {
    const urlObject = new URL(window.location)
    const searchParams = new URLSearchParams(form.serialize())
    searchParams.set(name, value)
    urlObject.pathname = url
    urlObject.search = searchParams.toString()
    return urlObject.href
  }

  document.querySelectorAll('[data-sticky]').forEach(el => {
    initSticky(el)
  })

  initIndex()
  hashAccordion()

  window.addUrlParameter = addUrlParameter
  window.constructURLForFormParams = constructURLForFormParams

})
