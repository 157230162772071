import $ from 'jquery'

export function scrollAndOpenSection(targetId) {
  const target = document.getElementById(targetId.substring(1))
  if (!target) {
    return
  }
  const sectionEl = target.nextElementSibling
  if (sectionEl && sectionEl.classList.contains('section-body')) {
    const selfAndParents = $(sectionEl)
      .parents('.collapse')
      .addBack()
    // trigger async collapse function to update icons
    // immediately open self and parent sections, so scroll would work
    selfAndParents.addClass('show')
    selfAndParents.trigger('show.bs.collapse')
    // remove collapsed class in section head, so opened icons are shown
    selfAndParents
      .siblings('.section-head--collapsable')
      .removeClass('collapsed')
  }

  target.scrollIntoView()
}

$.fn.openSection = function() {
  return this.each(function() {
    const $src = $(this)
    $src.click(evt => {
      if ($src.is('[data-open-subsections]')) {
        $('.section-body', $src.attr('data-section')).collapse('show')
      }
      // manual scroll, to account for header height
      evt.preventDefault()
      const hash = $src.attr('href')
      history.replaceState(null, null, hash)
      window.requestAnimationFrame(() =>
        scrollAndOpenSection($src.attr('href'))
      )
    })
  })
}
