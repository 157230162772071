import $ from 'jquery'

import { scrollAndOpenSection } from './openSection'

export default function hashAccordion() {
  // Event listener for accordion show event
  $('.collapse').on('show.bs.collapse', function(event) {
    if (!event.target.classList.contains('section-body')) {
      return
    }
    // skip hash functionality in search
    if (event.target.closest('.search-sidebar')) {
      return
    }
    const hash = event.target.previousElementSibling.id
    history.replaceState(null, null, '#' + hash)
  })

  $('.collapse').on('hidden.bs.collapse', function(event) {
    if (!window.location.hash) {
      return
    }
    if (!event.target.classList.contains('section-body')) {
      return
    }
    if (event.target.closest('.search-sidebar')) {
      return
    }
    const hash = event.target.previousElementSibling.id
    if (hash === window.location.hash.substring(1)) {
      history.replaceState(null, null, '#')
    }
  })

  window.setTimeout(() => scrollAndOpenSection(window.location.hash), 500)
}
